/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from "react";
import { colors } from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ChatIcon from "@material-ui/icons/ChatOutlined";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import PeopleIcon from "@material-ui/icons/People";
import { Label } from "./components";
import TextsmsIcon from "@material-ui/icons/Textsms";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import {
  CallMerge,
  CallToActionOutlined,
  CallToActionSharp,
  ExitToApp,
  GroupWork,
  HomeOutlined,
  NetworkCheck,
  TrendingUp
} from "@material-ui/icons";
import configs from "./configs";
import { DollarSign, Paperclip, PlusSquare } from "react-feather";

export default rbac_status => {
  // let roles = window.user?.user?.roles
  let roles = {};
  return [
    {
      title: "Modules",
      pages: [
        !window.user?.user?.roles["/dashboard/home"]
          ? undefined
          : {
              title: "Dashboard",
              href: "/dashboard/home",
              icon: HomeOutlined
            },

        !window.user?.user?.roles["/execute"]
          ? undefined
          : {
              title: "Buy Airtime / Data",
              href: "/execute",
              icon: CallToActionOutlined,
              children: [
                !window.user?.user?.roles["/execute/manual"]
                  ? undefined
                  : {
                      title: "Buy Airtime / Data",
                      href: "/execute/manual",
                      icon: CallToActionOutlined
                    },
                !window.user?.user?.roles["/execute/upload_excel"]
                  ? undefined
                  : {
                      title: "Buy via Excel",
                      href: "/execute/upload_excel",
                      icon: CallToActionOutlined
                    }
              ].filter(item => item !== undefined)
            },
        !window.user?.user?.roles["/requests/successful"]
          ? undefined
          : {
              title: "Reports",
              href: "/requests/successful",
              icon: Paperclip,
              children: [
                !window.user?.user?.roles["/requests/successful"]
                  ? undefined
                  : {
                      title: "Successful Requests",
                      href: "/requests/successful",
                      icon: GroupWork
                    },
                !window.user?.user?.roles["/requests/failed"]
                  ? undefined
                  : {
                      title: "Pending Requests",
                      href: "/requests/failed",
                      icon: CallMerge
                    },
                !window.user?.user?.roles["/requests/balance"]
                  ? undefined
                  : {
                      title: "Balance",
                      href: "/requests/balance",
                      icon: CallToActionOutlined
                    }
              ].filter(item => item !== undefined)
            },
        !window.user?.user?.roles["/requests/sales"]
          ? undefined
          : {
              title: "Top Up",
              href: "/requests/sales",
              icon: TrendingUp,
              children: [
                !window.user?.user?.roles["/requests/successful"]
                  ? undefined
                  : {
                      title: "Buy Now",
                      href: "/management/top-up",
                      icon: PlusSquare
                    },
                !window.user?.user?.roles["/management/transactions"]
                  ? undefined
                  : {
                      title: "Purchases",
                      href: "/management/transactions",
                      icon: DollarSign
                    }
              ]
            },

        !window.user?.user?.roles["/administration"]
          ? undefined
          : {
              title: "Administration",
              href: "/administration",
              icon: TransferWithinAStationIcon,
              children: [
                !window.user?.user?.roles["/administration/admins"]
                  ? undefined
                  : {
                      title: "Users",
                      href: "/administration/admins",
                      icon: PeopleIcon
                    },
                !window.user?.user?.roles["/administration/service/accounts"]
                  ? undefined
                  : {
                      title: "Services and Balance",
                      href: "/administration/service/accounts",
                      icon: AccountBalanceIcon
                    }
              ].filter(item => item !== undefined)
            },
        !window.user?.user?.roles["/apis_sdks"]
          ? undefined
          : {
              title: "APIs & SDKS",
              href: "/apis_sdks",
              icon: SettingsIcon
            }
      ].filter(item => item !== undefined)
    }
  ];
};
