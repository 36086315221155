import React, { Suspense, useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";

import { ChatBar, NavBar, TopBar } from "./components";
import { connect, useDispatch } from "react-redux";
import { alertActions } from "../../actions/alertActions";
import feathersClient from "../../utils/feathersClient";
import { Redirect } from "react-router";
import { Placeholder, Preloader } from "react-preloading-screen";
import CircularProgress from "@material-ui/core/CircularProgress";
import { loginFailure, loginSuccess } from "../../actions";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  topBar: {
    zIndex: 2,
    position: "relative"
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden"
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: "0 0 auto"
  },
  content: {
    overflowY: "auto",
    flex: "1 1 auto"
  }
}));


const Dashboard = props => {
  const { route } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // props.autoLogin(props.history)

  const [loadingStatus, setLoadingStatus] = useState(0);

  const getGroupRoles = () => feathersClient.service("admin-group-roles").find().then(results => results.data);
  const getRoles = () => feathersClient.service("admin-roles").find().then(results => results.data);
  const getServiceclients = () => feathersClient.service("service-clients").find().then(results => results.data);



  useEffect(() => {
    let mounted = true;


    const authenticate = () => {
      setLoadingStatus(0);

      feathersClient.reAuthenticate().then(result => {
        // show application page
        console.log(result);
        window.user=result;
        dispatch(loginSuccess(result));


        window.logout=()=>{
          feathersClient.logout()
          localStorage.clear();
          window.user=null
          window.location='/login'
        }
        feathersClient.on('logout', () =>{
          
          window.logout()
        });

        getGroupRoles().then(permissions => {
          console.log("Permissions");
          console.log(permissions);

          window.permissions = permissions;
        }).then(() => {
          return getRoles().then(roles => {
            // console.log("Roles");
            // console.log(roles);

            window.roles = roles;
          });

        }).then(() => {
          return getServiceclients().then(serviceclients => {
            // console.log("Roles");
            // console.log(roles);

            window.serviceclients = serviceclients;
          });

        })
        
        .then(() => {
          setLoadingStatus(1);
        }).catch(err => {
          setLoadingStatus(-1);
          console.error(err);
        });


      }).catch(err => {
        // show login page
        setLoadingStatus(-1);
        console.error(err);
        // router.history.push('/login');
        dispatch(loginFailure(err));
        dispatch(alertActions.error(err));


      });

    };

    // if(mounted)
    authenticate();

    return () => {
      mounted = false;
    };
  }, []);


  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };


  return (

    <>
      {loadingStatus === 0 ? (
        <Preloader>
          <Placeholder>
            <CircularProgress color="secondary" disableShrink/>
          </Placeholder>
        </Preloader>
      ) : loadingStatus === 1 ? (

        <div className={classes.root}>
          <TopBar
            className={classes.topBar}
            onOpenNavBarMobile={handleNavBarMobileOpen}
          />
          <div className={classes.container}>
            <NavBar
              className={classes.navBar}
              onMobileClose={handleNavBarMobileClose}
              openMobile={openNavBarMobile}
            />
            <main className={classes.content}>
              <Suspense fallback={<LinearProgress/>}>
                {renderRoutes(route.routes)}
              </Suspense>
            </main>
          </div>

        </div>
      ) : (
        <Redirect to={"/login"}/>

      )
      }
    </>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};


function mapState(state) {
  return state;
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(Dashboard);
export default connectedApp;
