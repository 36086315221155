import io from "socket.io-client";
import feathers from "@feathersjs/client";
import configs from "../configs";
import socketio from '@feathersjs/socketio-client';
const rest = require('@feathersjs/rest-client');
const request = require('request');




let endpoint = configs.URL
let use_socketio=false;


const feathersClient = feathers();

if (use_socketio){
  const socket = io(endpoint);
  feathersClient.configure(socketio(socket,{
    timeout: 10000,
  }));
}else{
  const restClient = rest(endpoint,{
    headers: {}})
  feathersClient.configure(restClient.request(request));
}


feathersClient.configure(
  feathers.authentication({
    storage: window.localStorage,
  })
);
window.feathersError=(data)=>{
	console.log("Feathers Error")
  console.error(data)
  // window.logout()
}


//
//
// const channel=feathers.socketio(io(configs.URL),{
//   timeout: 15000
// })
// // const channel=rest(configs.URL).fetch(window.fetch)
//
// const feathersClient = feathers()
//   .configure(channel)
//   .configure(
//     feathers.authentication({
//       storage: window.localStorage
//     })
//   );
// window.feathersError=(data)=>{
// 	console.log("Feathers Error")
//   console.error(data)
//   window.logout()
// }
export default feathersClient;

