import routes from "./routes";
import feathersClient from "./utils/feathersClient";

console.log("window.user")
console.log(window.user)
export default {
  APPNAME:"Credofaster Partner CRM",
  // URL:"http://localhost:3030",
  URL:"https://partner.credofaster.co.ke/crm_api",
};
