import {constants} from './../constants';

const initialState = {
  loggedIn: false,
  user:null,
  // useer: {
  //   first_name: 'Shen',
  //   last_name: 'Zhi',
  //   email: 'demo@devias.io',
  //   avatar: '/images/avatars/avatar_11.png',
  //   bio: 'Brain Director',
  //   role: 'ADMIN' // ['GUEST', 'USER', 'ADMIN']
  // }
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.USER.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case constants.USER.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case constants.USER.LOGIN_FAILURE:
      return {};
    case constants.USER.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        user: null
      };

    default: {
      return state;
    }
  }
};

export default sessionReducer;
