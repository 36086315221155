import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import MomentUtils from "@date-io/moment";
import { Provider as StoreProvider, useDispatch } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { renderRoutes } from "react-router-config";

import theme from "./theme";
import { configureStore } from "./store";
import routes from "./routes";
import { CookiesNotification, GoogleAnalytics, ScrollReset } from "./components";
import "./mixins/chartjs";
import "./mixins/moment";
import "./mixins/validate";
import "./mixins/prismjs";
import "./mock";
import "./assets/scss/index.scss";
import feathersClient from "./utils/feathersClient";
import { SnackbarProvider } from "notistack";

const history = createBrowserHistory();
const store = configureStore();

const App = () => {
  const [rbac_status, setrbac_status] = useState(false);
  const [rbac_statusA, setrbac_statusA] = useState(false);

  useEffect(() => {
    feathersClient
      .reAuthenticate()
      .then(result => {
        // console.log('result')
        // console.log('result is',result)
        // console.log('role id is',result.user.client.RoleId)
      })
      .catch(err => {
        console.log("Error In Auth");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StoreProvider store={store}>
      <SnackbarProvider anchorOrigin={{ horizontal: "right", vertical: "top" }}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <ScrollReset />
              <GoogleAnalytics />
              <CookiesNotification />
              {renderRoutes(routes())}
            </Router>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </StoreProvider>
  );
};

export default App;
