import React, { Fragment, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Drawer, Divider, Paper, Avatar, Typography } from "@material-ui/core";
import { Hidden } from "@material-ui/core";

import useRouter from "../../../../utils/useRouter";
import { Navigation } from "../../../../components";
import navigationConfig from "../../../../navigationConfig";
import feathersClient from "../../../../utils/feathersClient";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    overflowY: "auto",
    backgroundColor: "rgba(255,255,255, 0.5)"
  },
  content: {
    padding: theme.spacing(2)
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content"
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  }
}));

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  const session = useSelector(state => state.session);

  const [rbac_status, setrbac_status] = useState(false);
  const [rbac_statusA, setrbac_statusA] = useState(false);

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    feathersClient
      .reAuthenticate()
      .then(result => {
        setrbac_status(result.user.client.RoleId < 3);
        setrbac_statusA(true);
      })
      .catch(err => {
        console.log("Error In Auth");
        setrbac_status(false);
        setrbac_statusA(true);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={"/images/avatars/avatar_8.png"}
          to="/profile"
        />
        <Typography className={classes.name} variant="h4">
          {window.user.user.name}
        </Typography>
        <Typography variant="body2">{window.user.user.email}</Typography>
        <Typography variant="body2">Partner: {window.user.user.client?.CompanyName}</Typography>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {rbac_statusA &&
          navigationConfig(!rbac_status).map(list => (
            <Navigation component="div" key={list.title} pages={list.pages} title={list.title} />
          ))}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper {...rest} className={clsx(classes.root, className)} elevation={1} square>
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
