import React, {Fragment, Suspense} from "react";
import {renderRoutes} from "react-router-config";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/styles";
import {LinearProgress} from "@material-ui/core";

import {Topbar} from "./components";
import {alertActions} from "../../actions";
import {connect, useDispatch} from "react-redux";
import {Redirect} from "react-router";

const useStyles = makeStyles(theme => ({
    content: {}
}));


const Auth = props => {
    const {route} = props;
    const dispatch = useDispatch();

    const classes = useStyles();
    // Try to authenticate with the JWT stored in localStorage
    //   registerAuthListeners(useDispatch())

    if (props.session.loggedIn) {
        return <Redirect to="/"/>;
    } else {

        return (
            <Fragment>
                <Topbar/>
                <main className={classes.content}>
                    <Suspense fallback={<LinearProgress/>}>
                        {renderRoutes(route.routes)}
                    </Suspense>
                </main>
            </Fragment>
        );
    }
};

Auth.propTypes = {
    route: PropTypes.object
};

function mapState(state) {
    return state;
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(Auth);
export default connectedApp;
