import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, colors } from '@material-ui/core';

import feathersClient from "../../../../utils/feathersClient";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  }
}));

const Statistics = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [statisticsA, setStatisticsA] = useState(null);
  const [statisticsB, setStatisticsB] = useState(null);


  useEffect(() => {
    let mounted = true;

    const fetch = () => {
      // feathersClient.service("stats").find({query:{type:'balances'}}).then(results => {
      //   if (mounted) {
      //     setStatisticsA(results);
      //   }
      //
      // }).catch(window.feathersError);
      //
      // feathersClient.service("stats").find({query:{type:'users'}}).then(results => {
      //   if (mounted) {
      //     setStatisticsB(results);
      //   }
      //
      // }).catch(window.feathersError);

    };

    fetch();

    return () => {
      mounted = false;
    };
  }, []);


  // useEffect(() => {
  //   let mounted = true;
  //   if (mounted) {
  //     setStatistics({
  //       payout: '4,250',
  //       projects: '12,500',
  //       visitors: '230',
  //       watching: '5'
  //     });
  //   }


  //   return () => {
  //     mounted = false;
  //   };
  // }, []);

  if (!statisticsA || !statisticsB) {
    return null;
  }

  return (
    <div>
      <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="center"
        container
        justify="space-between"
      >


        {Object.keys(statisticsA).map((d, i)=>(
          <Grid
          className={classes.item}
          key={i}
          item
          md={6}
          sm={6}
          xs={12}
        >
          <Typography variant="h2">{statisticsA[d]? statisticsA[d].toLocaleString():"-"}</Typography>
          <Typography
            className={classes.overline}
            variant="overline"
          >
            {d}
          </Typography>
        </Grid>

        ))}
        
        
        
      </Grid>
    </Card>

    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="center"
        container
        justify="space-between"
      >


        {Object.keys(statisticsB).map((d, i)=>(
          <Grid
          className={classes.item}
          key={i}
          item
          md={6}
          sm={6}
          xs={12}
        >
          <Typography variant="h2">{statisticsB[d]? statisticsB[d].toLocaleString():"-"}</Typography>
          <Typography
            className={classes.overline}
            variant="overline"
          >
            {d}
          </Typography>
        </Grid>

        ))}
        
        
        
      </Grid>
    </Card>
    </div>
  );
};

Statistics.propTypes = {
  className: PropTypes.string
};

export default Statistics;
