/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error";
import DashboardLayout from "./layouts/Dashboard";

/*_TPL_START_IMPORT_ROUTE_TPL_*/
import Overview from "./views/Overview";

/*_TPL_END_IMPORT_ROUTE_TPL_*/

const routes = rbac_status => {
  // let roles = window.user?.user?.roles
  // let roles = {};

  // if(window.user)

  return [
    /*_TPL_START_ROUTE_TPL_*/
    {
      path: "/",
      exact: true,
      component: () => <Redirect to="/overview" />
    },
    {
      path: "/login",
      component: AuthLayout,
      routes: [
        {
          path: "/login",
          exact: true,
          component: lazy(() => import("./views/Login"))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      path: "/register",
      component: AuthLayout,
      routes: [
        {
          path: "/register",
          exact: true,
          component: lazy(() => import("./views/Register/Register"))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      path: "/errors",
      component: ErrorLayout,
      routes: [
        {
          path: "/errors/error-401",
          exact: true,
          component: lazy(() => import("./views/Error401"))
        },
        {
          path: "/errors/error-404",
          exact: true,
          component: lazy(() => import("./views/Error404"))
        },
        {
          path: "/errors/error-500",
          exact: true,
          component: lazy(() => import("./views/Error500"))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      route: "*",
      component: DashboardLayout,
      routes: [
        {
          path: "/overview",
          exact: true,
          component: Overview
        },

        window.user?.user?.roles["/management/groups"]
          ? undefined
          : {
              path: "/management/groups",
              exact: true,
              component: () => <Redirect to="/management/groups/list" />
            },
        window.user?.user?.roles["/management/groups/"]
          ? undefined
          : {
              path: "/management/groups/:tab",
              exact: true,
              component: lazy(() => import("./views/GroupsManagement"))
            },

        window.user?.user?.roles["/management/transactions"]
          ? undefined
          : {
              path: "/management/transactions",
              exact: true,
              component: lazy(() => import("./views/Transactions"))
            },
        {
          path: "/management/top-up",
          exact: true,
          component: lazy(() => import("./views/TopUp/TopUp.jsx"))
        },
        window.user?.user?.roles["/requests/sales"]
          ? undefined
          : {
              path: "/requests/sales",
              exact: true,
              component: lazy(() => import("./views/SalesRequests"))
            },
        window.user?.user?.roles["/requests/successful"]
          ? undefined
          : {
              path: "/requests/successful",
              exact: true,
              component: lazy(() => import("./views/SuccRequests"))
            },
        window.user?.user?.roles["/requests/failed"]
          ? undefined
          : {
              path: "/requests/failed",
              exact: true,
              component: lazy(() => import("./views/FailRequests"))
            },
        window.user?.user?.roles["/requests/balance"]
          ? undefined
          : {
              path: "/requests/balance",
              exact: true,
              component: lazy(() => import("./views/Balance"))
            },

        window.user?.user?.roles["/execute/requests"]
          ? undefined
          : {
              path: "/execute/requests",
              exact: true,
              component: lazy(() => import("./views/ExecutedRequests"))
            },

        window.user?.user?.roles["/execute/manual"]
          ? undefined
          : {
              path: "/execute/manual",
              exact: true,
              component: lazy(() => import("./views/Execute/Manual"))
            },

        window.user?.user?.roles["/execute/upload_excel"]
          ? undefined
          : {
              path: "/execute/upload_excel",
              exact: true,
              component: lazy(() => import("./views/Execute/Excel"))
            },
        window.user?.user?.roles["/dashboard/home"]
          ? undefined
          : {
              path: "/dashboard/home",
              exact: true,
              component: lazy(() => import("./views/Dashboard"))
            },
        window.user?.user?.roles["/morupload/upload_excel"]
          ? undefined
          : {
              path: "/morupload/upload_excel",
              exact: true,
              component: lazy(() => import("./views/Execute/Excel"))
            },

        window.user?.user?.roles["/execute/groups"]
          ? undefined
          : {
              path: "/execute/groups",
              exact: true,
              component: lazy(() => import("./views/Execute/FromGroup"))
            },

        window.user?.user?.roles["/administration"]
          ? undefined
          : {
              path: "/administration",
              exact: true,
              component: () => <Redirect to="/administration/admins" />
            },

        window.user?.user?.roles["/administration/"]
          ? undefined
          : {
              path: "/administration/:tab",
              exact: true,
              component: lazy(() => import("./views/Administration"))
            },
        window.user?.user?.roles["/users"]
          ? undefined
          : {
              path: "/users",
              exact: true,
              component: lazy(() => import("./views/Users"))
            },

        window.user?.user?.roles["/administration/service/"]
          ? undefined
          : {
              path: "/administration/service/:tab",
              exact: true,
              component: lazy(() => import("./views/ServiceManagement"))
            },

        window.user?.user?.roles["/apis_sdks"]
          ? undefined
          : {
              path: "/apis_sdks",
              exact: true,
              component: () => <Redirect to="/apis_sdks/docs" />
            },

        window.user?.user?.roles["/apis_sdks/"]
          ? undefined
          : {
              path: "/apis_sdks/:tab",
              exact: true,
              component: lazy(() => import("./views/APISDKManagement"))
            },

        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ].filter(item => item !== undefined)
    }
    /*_TPL_END_ROUTE_TPL_*/
  ];
};

export default routes;
