import { constants } from "./../constants";


export const alertActions = {
  success,
  error,
  clear
};

function success(message) {
  return { type: constants.ALERT.SUCCESS, message };
}

function error(message) {
  return { type: constants.ALERT.ERROR, message };
}

function clear() {
  return { type: constants.ALERT.CLEAR };
}