export const constants = {
  USER: {

    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
    LOGOUT: "USERS_LOGOUT",
  },

  ALERT: {
    SUCCESS: "ALERT_SUCCESS",
    ERROR: "ALERT_ERROR",
    CLEAR: "ALERT_CLEAR"
  }
};