import {constants} from "./../constants";
import feathersClient from "../utils/feathersClient";

export const logout = () => {
  return dispatch => {
      feathersClient.logout()
      dispatch({
          type: constants.USER.LOGOUT
      });
      window.logout()
  }
};

export const autoLogin = (result) => {
  return dispatch => {
    dispatch(loginSuccess(result));
  }
};

export const loginSuccess = (user) => {
    return { type: constants.USER.LOGIN_SUCCESS, user };
};

export const loginFailure = (error) => {
    return { type: constants.USER.LOGIN_FAILURE, error };
};
